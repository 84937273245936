import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout/t1/layout"
import GetStarted from "../components/get-started"
const GetStartedPage = () => {
  return (
    <Layout>
      <GetStarted />
    </Layout>
  )
}

export default GetStartedPage
